import React from 'react';
import styled, { css } from 'styled-components';
import { ShortContentFragment, LibraryType } from 'generated/graphql';
import Breakpoints from '../../themes/constants/breakpoints';
import useAuthAction from '../../hooks/useAuthAction';
import useAuthRedirect from '../../hooks/useAuthRedirect';
import getMetaParams from '../../helpers/getMetaParams';
import useChangeLibraryType from '../../hooks/useChangeLibraryType';
import convertRussianToLatin from '../../helpers/convertRussianToLatin';

import { ReactComponent as StarIcon } from '../../media/icons/star.svg';
import { ReactComponent as HeadphonesIcon } from '../../media/icons/headphones.svg';
import { ReactComponent as ReadIcon } from '../../media/icons/read.svg';
import { ReactComponent as ReadedIcon } from '../../media/icons/readed.svg';
import { ReactComponent as DownloadIcon } from '../../media/icons/download.svg';

// Styles
const List = styled.ul`
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
`;
const ListItem = styled.li``;
const Button = styled.button<{ active?: boolean }>`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 5px;
    height: 66px;
    width: 60px;
    cursor: pointer;
    background: none;
    border: 0;
    fill: ${({ active, theme }) => (active ? theme.palette.primary : theme.palette.black)};
    @media only screen and (${Breakpoints.TABLET}) {
        width: 130px;
        height: 150px;
    }
    @media only screen and (${Breakpoints.LAPTOP}) {
        width: 80px;
        height: 90px;
    }
`;
const ItemText = styled.p`
    margin: 20px 0 0;
    font-size: 10px;
    color: ${({ theme }) => theme.color.black};
    @media only screen and (${Breakpoints.TABLET}) and (max-width: 1024px) {
        margin-top: 22px;
        font-size: 16px;
    }
`;
const icon = css<{ $isActive?: boolean }>`
    color: ${({ theme, $isActive }) => ($isActive ? theme.color.selectedColor : theme.palette.black)};
    width: auto;
    display: block;
    @media only screen and (${Breakpoints.TABLET}) and (max-width: 1024px) {
        height: 40px;
    }
`;
const StyledStarIcon = styled(StarIcon)<{ $isActive?: boolean }>`
    ${icon}
`;
const StyledHeadphonesIcon = styled(HeadphonesIcon)`
    ${icon}
`;
const StyledTextIcon = styled(ReadIcon)`
    ${icon}
`;
const StyledCheckIcon = styled(ReadedIcon)<{ $isActive?: boolean }>`
    ${icon}
`;
const StyledDownloadIcon = styled(DownloadIcon)`
    ${icon}
`;

interface IControls {
    book: ShortContentFragment;
    fetchBookCallback?: () => void;
    className?: string;
}

export const Controls = ({
    book: {
        id, top, link, readed, metaContentParams, name,
    },
    fetchBookCallback,
    className,
}: IControls) => {
    const authAction = useAuthAction();

    const [addReadedType, removeReadedType] = useChangeLibraryType({
        id,
        type: LibraryType.Readed,
        callback: fetchBookCallback,
    });

    const [, removeReadNowType] = useChangeLibraryType({
        id,
        type: LibraryType.ReadNow,
        callback: fetchBookCallback,
    });

    const [addTopType, removeTopType] = useChangeLibraryType({
        id,
        type: LibraryType.Top,
        callback: fetchBookCallback,
    });

    const authRedirect = useAuthRedirect();

    const handleDownload = () => {
        const metaEpub = getMetaParams(metaContentParams, 'epub');
        const url = `/gate/p/${link}/${metaEpub}`;
        const filename = `${convertRussianToLatin(name, 12)}.epub`;

        fetch(url)
            .then((response) => response.blob())
            .then((blob) => {
                const linkEl = document.createElement('a');
                linkEl.href = window.URL.createObjectURL(blob);
                linkEl.download = filename;
                linkEl.click();
            });
    };

    return (
        <List className={className}>
            <ListItem>
                <Button
                    type="button"
                    active={top}
                    onClick={authAction(() => (top ? removeTopType() : addTopType()))}
                >
                    <StyledStarIcon $isActive={top} />
                    <ItemText>Мой TOП</ItemText>
                </Button>
            </ListItem>
            {getMetaParams(metaContentParams, 'abook_exist') && (
                <ListItem>
                    <Button
                        type="button"
                        onClick={authRedirect(`/books/${id}/listen`)}
                    >
                        <StyledHeadphonesIcon />
                        <ItemText>Слушать</ItemText>
                    </Button>
                </ListItem>
            )}
            {getMetaParams(metaContentParams, 'ebook_exist') && (
                <ListItem>
                    <Button
                        type="button"
                        onClick={authRedirect(`/books/${id}/read`)}
                    >
                        <StyledTextIcon />
                        <ItemText>Читать</ItemText>
                    </Button>
                </ListItem>
            )}
            <ListItem>
                <Button
                    type="button"
                    active={readed}
                    onClick={authAction(() => {
                        if (readed) {
                            removeReadedType();
                        } else {
                            addReadedType();
                            removeReadNowType();
                        }
                    })}
                >
                    <StyledCheckIcon $isActive={readed} />
                    <ItemText>Читал</ItemText>
                </Button>
            </ListItem>
            <ListItem>
                <Button
                    type="button"
                    onClick={authAction(() => {
                        handleDownload();
                    })}
                >
                    <StyledDownloadIcon />
                    <ItemText>Скачать</ItemText>
                </Button>
            </ListItem>
        </List>
    );
};

Controls.defaultProps = {
    className: '',
    fetchBookCallback: null,
};
